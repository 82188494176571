<template>
  <div>
    <b-card bg-variant="Default" title="Utenti Principali">
        <div v-if="Caricato">
          <validation-observer ref="simpleRules">
                <b-form>
                    <b-row>
                        <b-col md="3" lg="3">
                            <b-form-group label="Super Admin Principale" label-for="super_admin_id">
                                <b-form-select
                                    v-model="campiform.super_admin_id"
                                    :options="options_user_super_admin"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3" lg="3">
                            <b-form-group label="Reseller Principale" label-for="reseller_id">
                                <b-form-select
                                    v-model="campiform.reseller_id"
                                    :options="options_user_reseller"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col md="3" lg="3">
                            <b-form-group label="Assistente Principale" label-for="assistant_id">
                                <b-form-select
                                    v-model="campiform.assistant_id"
                                    :options="options_user_assistente"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col md="3" lg="3" style="margin-top: 20px;">
                            <b-button
                                variant="primary"
                                type="submit"
                                :disabled="action_press"
                                @click.prevent="validationForm()"
                            >
                                <div v-if="action_press">Salvaggio in corso <b-spinner small class="ml-1" /></div>
                                <div v-else>Salva</div> 
                            </b-button>
                        </b-col>

                    </b-row>
                </b-form>
                
            </validation-observer>
        </div>
        <div v-else class="text-center">
            <b-spinner variant="primary" class="my-1" />
        </div>
            
    </b-card>

    <card-check-fic
      :api_token_fic_scadenza.sync = "api_token_fic_scadenza"
      :loading_fic = "loading_fic"
      :api_token_fic_esito_controllo = "api_token_fic_esito_controllo"
      :url_fic_auth = "url_fic_auth"
      :api_token_errore_caricamento.sync = "api_token_errore_caricamento"
    />

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import it from 'vee-validate/dist/locale/it.json'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BCard, BCardText, BRow, BCol, BBadge, BPagination, BSpinner, BFormGroup, BForm, BInputGroup, BFormInput, BInputGroupPrepend, BInputGroupAppend, BDropdownDivider, BFormSelect, BDropdown, BDropdownItem, BButton, BSidebar, VBToggle, VBTooltip
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, digits, alphaDash, length, min
} from '@validations'
import CardCheckFic from './CardCheckFic'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    it,
    BCardCode,
    BCard, 
    BCardText,
    BRow, 
    BCol, 
    BBadge,
    BPagination,
    BSpinner,
    BInputGroup,
    BFormGroup,
    BForm, 
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BDropdownDivider,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BButton,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent, 

    CardCheckFic,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
        campiform: {
            reseller_id: '',
            assistant_id: '',
            super_admin_id: '',
        },

        required,
        email,
        alphaDash, 
        digits,
        length,
        min,
        options_user_super_admin: [ ],
        options_user_reseller: [ ],
        options_user_assistente: [ ],

        Caricato: false,
        errore_caricamento: false,
        submitted: false,
        action_press: false,

        userData: {},

        block_check_token_api_fic: false,
        api_token_fic_scadenza: '',
        api_token_fic_esito_controllo: false,
        url_fic_auth: '', 
        loading_fic: true,
        api_token_errore_caricamento: false,

    }
  },
  computed: {

  },
  created() {
    //errori validazione in italiano
    localize({
      it: {
        messages: it.messages,
      },
    })

    const userCrmAssistentiPromise = this.$http.get('api/crm/user/list?columnFilters={"role.type":"employee", "role.name":"assistant" }&page=1&perPage=100&status=active')
    const userCrmResellerAssistentiPromise = this.$http.get('api/crm/user/list?columnFilters={"role.type":"employee", "role.name":"reseller" }&page=1&perPage=100&status=active')
    const userCrmSuperadminPromise = this.$http.get('api/crm/user/list?columnFilters={"role.type":"employee", "role.name":"super_admin" }&page=1&perPage=10&status=active')
    const attualiImpostazioni = this.$http.get('api/crm/impostazioni/section/1')

    Promise.all([userCrmAssistentiPromise, userCrmResellerAssistentiPromise,userCrmSuperadminPromise,attualiImpostazioni]).then(response => {
        if(response[0].data.statusCode===200){
            //caricamento lista utenti employee assistenti
            let i = 0;
            while (i < response[0].data.reply.meta.total) {
                this.options_user_assistente[i] = { value: response[0].data.reply.data[i].id, text: response[0].data.reply.data[i].name+' '+response[0].data.reply.data[i].surname },
                
                i++;
            }

            if(response[1].data.statusCode===200){
                //caricamento lista utenti employee reseller
                let u = 0;
                while (u < response[1].data.reply.meta.total) {
                    this.options_user_reseller[u] = { value: response[1].data.reply.data[u].id, text: response[1].data.reply.data[u].name+' '+response[1].data.reply.data[u].surname },
                    
                    u++;
                }

                if(response[2].data.statusCode===200){
                  //caricamento lista utenti super_admin
                  let u = 0;
                  while (u < response[2].data.reply.meta.total) {
                      this.options_user_super_admin[u] = { value: response[2].data.reply.data[u].id, text: response[2].data.reply.data[u].name+' '+response[2].data.reply.data[u].surname },
                      
                      u++;
                  }

                    if(response[3].data.statusCode===200){
                        //caricamento dati impostazioni
                        this.campiform.reseller_id = response[3].data.reply[0].valore;
                        this.campiform.assistant_id = response[3].data.reply[1].valore;
                        this.campiform.super_admin_id = response[3].data.reply[2].valore;

                        this.Caricato = true;
                        this.errore_caricamento = false;

                    } else {
                        this.Caricato = false;
                        this.errore_caricamento = true;
                    }
                  
                  } else {
                      this.Caricato = false;
                      this.errore_caricamento = true;
                  }
            
            } else {
                this.Caricato = false;
                this.errore_caricamento = true;
            }
        } else {
            this.Caricato = false;
            this.errore_caricamento = true;
        }
    })

    this.userData = JSON.parse(localStorage.getItem('userData'))
    this.id_utente_loggato = this.userData.id

    this.checkTokenApiFIC(this.userData.role)

  },
  methods: {
    validationForm(){
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.action_press = true

          console.log("qui")
          console.log(this.campiform)

          this.$http.post('api/crm/impostazioni/update', 
            this.campiform
            ).then(response => {
            
              this.action_press = false

              if((response.data.statusCode>=200) && (response.data.statusCode<=299)){
                //risposta positiva
                if(response.data.reply.risposta != ''){
                  //salvataggio effettuato
                    this.$swal({
                        icon: 'success',
                        title: response.data.reply.risposta,
                        confirmButtonText: 'chiudi',
                        customClass: {
                        confirmButton: 'btn btn-success',
                        },
                    })

                  }
                  
                } else {
                  //questo non dovrebbe mai accadere ... ma non si sa mai -> visualizzo risposta ma non faccio redirect
                  this.$swal({
                      icon: 'warning',
                      title: 'Si è verificato un errore',
                      text: 'Errore '+response.data.statusCode+', ti preghiamo di contattate l\'assistenza tecnica per segnalare il problema',
                      customClass: {
                      confirmButton: 'btn btn-outline-primary',
                      },
                  })
                }

            }).catch(e => {
              console.log(e);
            });

        }
      })
    },

    checkTokenApiFIC(ruolo_employeee){
      if(ruolo_employeee == 'SuperAdmin'){
        this.block_check_token_api_fic =  true;

        this.$http.get('api/crm/fic/check_token').then(response => {
          if(response.data.statusCode === 200){
            //ok dati ricevuti

            if(response.data.reply.dati_config_token === null){
              //mancano i dati di configurazione
              this.api_token_fic_esito_controllo = response.data.reply.esito_controllo;
              this.url_fic_auth = response.data.reply.url_fic_auth;

              console.log("FIC 01 -this.api_token_fic_esito_controllo -> "+this.api_token_fic_esito_controllo)
            } else {
              this.api_token_fic_scadenza = response.data.reply.dati_config_token.token_expires_datatime;
              //console.log("this.api_token_fic_scadenza -> "+this.api_token_fic_scadenza)

              this.api_token_fic_esito_controllo = response.data.reply.esito_controllo
              this.url_fic_auth = response.data.reply.url_fic_auth;

              console.log("FIC 02")
            }            

            this.loading_fic = false
            this.api_token_errore_caricamento =  false;
          } else {
            //errore da api
            this.loading_fic = false
            this.api_token_errore_caricamento = true;
          }
        
        }).catch(e => {
          this.api_token_errore_caricamento = true;
        });        

      } else {
        this.block_check_token_api_fic =  false;
      }
    }
    
  }
}
</script>